import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Subheading } from '../UI';
import SocialLink from '../UI/SocialLink';
import { borderRadiusRound } from '../../styleTokens';

import { GatsbyImage } from 'gatsby-plugin-image';

const bp = '@media screen and (min-width: 1296px)';

const ImageWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 442px;
`;

const ContentBody = styled.div`
  max-width: 568px;
  padding: 0;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.darkgrey};
  h4 {
    margin: 20px 0;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.9px;
  }
  @media screen and (min-width: 1250px) {
    h4 {
      font-size: ${({ theme }) => theme.fontSizes[4]};
    }
    p {
      font-size: ${({ theme }) => theme.fontSizes[3]};
    }
  }
`;

const TeamMemberContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  justify-content: center;
  ${bp} {
    justify-content: space-between;
    :nth-of-type(even) {
      flex-flow: row-reverse wrap;
    }
  }
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const SocialLinkContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  a {
    margin-right: 20px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.blue};
    opacity: 0.3;

    width: 2em;
    height: 2em;
    ${borderRadiusRound};
    svg {
      color: white;
    }
  }
`;
// image is a sharp graphql fixed image
const TeamMember = ({
  name,
  bio,
  title,
  image,
  linkedin,
  twitter,
  ...rest
}) => (
  <TeamMemberContainer {...rest}>
    <ImageWrapper>
      <GatsbyImage image={image} alt={name} />
    </ImageWrapper>
    <ContentBody>
      <Subheading>{name}</Subheading>
      <h4>{title}</h4>
      <p>{bio}</p>
      <SocialLinkContainer>
        {linkedin && <SocialLink type="linkedin" link={linkedin} />}
        {twitter && <SocialLink type="twitter" link={twitter} />}
      </SocialLinkContainer>
    </ContentBody>
  </TeamMemberContainer>
);

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  bio: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  image: PropTypes.shape({ src: PropTypes.string }),
};

export default TeamMember;
