import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../theme';
import leftQuote from '../../images/new/quote_left.svg';
import rightQuote from '../../images/new/quote_right.svg';
import { CUSTOM_BREAKPOINTS } from '../../constants/breakpoints';

const quoteStyle = css`
  color: ${theme.colors.blue};
  position: absolute;

  width: 45px;
  ${CUSTOM_BREAKPOINTS['768']} {
    width: 60px;
  }
`;

const Quote = styled.blockquote`
  margin: 0;
  font-size: ${theme.fontSizes[3]};

  @media screen and (min-width: 600px) {
    font-size: ${theme.fontSizes[6]};
  }
`;
const BigQuote = ({ children, ...rest }) => (
  <div
    css={css`
      position: relative;
      text-align: center;
      display: inline-block;
      max-width: 600px;
      padding: ${theme.spacing[6]};
      ${CUSTOM_BREAKPOINTS['768']} {
        padding: ${theme.spacing[7]} ${theme.spacing[13]};
      }
    `}
    {...rest}
  >
    <Quote>
      <img
        css={quoteStyle}
        alt="left-quote"
        style={{
          top: 0,
          left: 0,
          transform: `translateY(-${theme.fontSizes['1x']})`,
        }}
        src={leftQuote}
      />
      <div
        style={{
          color: theme.colors.text,
          margin: `0 ${theme.spacing['5x']}`,
        }}
      >
        {children}
      </div>
      <img
        css={quoteStyle}
        alt="right-quote"
        style={{
          bottom: 0,
          right: 0,
          transform: `translateY(${theme.fontSizes['1x']})`,
        }}
        src={rightQuote}
      />
    </Quote>
  </div>
);

export default BigQuote;
