import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { CUSTOM_BREAKPOINTS, isSmallScreen } from '../../../constants/breakpoints';

const Section = styled.section`
  
`;

const Container = styled.div`
  display: flex;

  align-items: center;
  flex-wrap: wrap-reverse;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 60px;
  ${CUSTOM_BREAKPOINTS[480]} {
    margin-bottom: 100px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  ${CUSTOM_BREAKPOINTS[480]} {
    display: block;
    width: initial;
    margin-bottom: 0;
  }
`;

const ContentContainer = styled.div`
  max-width: 568px;
  box-sizing: content-box;
  flex: 1 1 340px;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${({ theme }) => theme.colors.text};
  line-height: normal;
  letter-spacing: normal;
  p:last-of-type {
    margin-bottom: 0;
  }
  ${CUSTOM_BREAKPOINTS[480]} {
    font-size: ${({ theme }) => theme.fontSizes[4]};
    padding-right: 115px;
    max-width: 600px;
    font-weight: 400;
  }
`;

const MissionStatement = () => (
  <Section>
    <Title>Our Mission</Title>
    <StaticQuery
      query={graphql`
        query MissionStatment {
          file(relativePath: { eq: "mission.md" }) {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ContentContainer
            dangerouslySetInnerHTML={{
              __html: data.file.childMarkdownRemark.html,
            }}
          />
          <ImageContainer>
            {isSmallScreen() ? (
              <StaticImage
                src="../../../images/our_mission.svg"
                alt="Our mission"
                placeholder="blurred"
                layout="fixed"
                width={340}
                quality={100}
              />
            ) : (
              <StaticImage
                src="../../../images/our_mission.svg"
                alt="Our mission"
                placeholder="blurred"
                layout="fixed"
                width={526}
                quality={100}
              />
            )}
          </ImageContainer>
        </Container>
      )}
    />
  </Section>
);

export default MissionStatement;
