import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Mission from '../components/About/Mission';
import { Section, SectionTitle, Container as UIContainer } from '../components/UI';
import { graphql } from 'gatsby';
import TeamMember from '../components/TeamMember';
import styled from '@emotion/styled';
import BigQuote from '../components/UI/BigQuote';
import theme from '../theme';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';

const GreySectionTitle = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.darkgrey};
  padding-bottom: 60px;
  padding-top: 100px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 190px;
    padding-bottom: 100px;
  }
`;

const Container = styled(UIContainer)`
  padding: 0 20px;
`;

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="About" />
      <div
        css={css`
          text-align: center;
          padding: ${theme.spacing[8]} ${theme.spacing[7]} 60px;
          ${EMOTION_BREAKPOINTS.sm} {
            padding: ${theme.spacing[8]} ${theme.spacing[7]} 100px;
          }
        `}
      >
        <BigQuote>
          We help teachers create engaging assessments for their students.
        </BigQuote>
      </div>
      <Container>
        <Mission />
      </Container>
      <Section>
        <GreySectionTitle>The Team</GreySectionTitle>
        <Container>
          {data.allContentfulTeamMember.edges.map((team) => (
            <TeamMember
              key={team.node.id}
              name={team.node.name}
              title={team.node.positionTitle}
              bio={team.node.bio.bio}
              twitter={team.node.twitter}
              linkedin={team.node.linkedin}
              image={team.node.photo.gatsbyImageData}
            />
          ))}
        </Container>
      </Section>
      <Container></Container>
    </Layout>
  );
};

export const AboutQuery = graphql`
  query AboutQuery {
    allContentfulTeamMember {
      edges {
        node {
          id
          name
          positiontitle
          bio {
            bio
          }
          linkedin
          facebook
          twitter
          photo {
            gatsbyImageData(width: 442)
          }
        }
      }
    }
  }
`;
export default AboutPage;
